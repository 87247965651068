//let baseUrl = "http://localhost:8080";
let baseUrl = "https://www.kalenderkonten.com/api-baksolava";
// let baseUrl = "https://www.kalenderkonten.com/app-propolis";
// let baseUrl = "https://www.kalenderkonten.com/api-kalender";
//let baseUrl = "https://www.kalenderkonten.com/api-mesinbengkel";
let indexEvent = `${baseUrl}/kegiatan`;
let showEvent = `${baseUrl}/kegiatan/`;
let createEvent = `${baseUrl}/kegiatan`;
let updateEvent = `${baseUrl}/kegiatan/`;
let deleteEvent = `${baseUrl}/kegiatan/delete/`;

let indexPerson = `${baseUrl}/person`;
let showPerson = `${baseUrl}/person`;
let createPerson = `${baseUrl}/person`;
let updatePerson = `${baseUrl}/person/update/`;
let deletePerson = `${baseUrl}/person/delete/`;
// let urlPerson = baseUrl + "person";

let indexTeam = `${baseUrl}/team`;
let showTeam = `${baseUrl}/team/`;
let createTeam = `${baseUrl}/team`;
let updateTeam = `${baseUrl}/team/`;
let deleteTeam = `${baseUrl}/team/delete/`;

let memberUrl = baseUrl + "/datamember";
let category = baseUrl + "/category";

let logUrl = `${baseUrl}/admin/login`;
export {
  indexEvent,
  showEvent,
  createEvent,
  updateEvent,
  deleteEvent,
  logUrl,
  //   ------------------
  indexPerson,
  showPerson,
  createPerson,
  updatePerson,
  deletePerson,
  //   ------------------
  indexTeam,
  showTeam,
  createTeam,
  updateTeam,
  deleteTeam,
  //   --------------------
  memberUrl,
  baseUrl,
  category,
};
